/**
 * Custom function to preview files in an Ant Design upload list.
 *
 * @function
 * @async
 * @param {Object} file - The file object of the current file item.
 * @param {function} showFile - A function to display the file if not an image.
 * @returns {Promise} The promise of the preview operation, if it exists.
 */

export const onPreview = async (file, showFile) => {
  if (!file.rawFile) {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  } else {
    showFile(file.rawFile);
  }
};
