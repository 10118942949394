/**
 * Handles removing a file from the file list.
 *
 * @function
 * @async
 * @param {Array} name - Array of strings to form the unique fileKey.
 * @param {Object} file - The file object to be removed.
 * @param {Array} filesConfiguration - The configuration list for all files.
 * @param {function} setFilesConfiguration - The setter function for the files configuration.
 * @param {function} setFieldsFileList - The setter function for the file list.
 * @param {string} purpose - Purpose of the createUpdateContainer.
 * @param {function} deleteFile - The function to delete a file from the server.
 * @param {function} message - The function to display a message to the user.
 * @param {function} setFilesToUpload - A function to set filesToUpload.
 * @returns {Promise<void>} Promise that resolves when the file removal operation is completed.
 */
export const onRemove = async (
  name,
  file,
  filesConfiguration,
  setFilesConfiguration,
  setFieldsFileList,
  purpose,
  deleteFile,
  message,
  setFilesToUpload
) => {
  try {
    setFieldsFileList((prevList) => {
      const updatedList = { ...prevList };
      const fieldArray = updatedList[name];

      if (fieldArray) {
        const fileToRemoveIndex = fieldArray.indexOf(file);

        if (fileToRemoveIndex !== -1) {
          fieldArray.splice(fileToRemoveIndex, 1);
        }
      }
      return updatedList;
    });

    setFilesToUpload((prevList) => {
      const existingFileIndex = prevList.findIndex(
        (fileItem) => fileItem.file === file.file
      );
      return [
        ...prevList.slice(0, existingFileIndex),
        ...prevList.slice(existingFileIndex + 1)
      ];
    });
    if (purpose === 'edit') {
      if (file.file._id) {
        await deleteFile(file.file._id);
      }
    }
    const updatedFileIndexAndTypeList = filesConfiguration.filter(
      (item) => item.fileKey !== name.join('')
    );
    setFilesConfiguration(updatedFileIndexAndTypeList);
  } catch (error) {
    message(error);
  }
};
