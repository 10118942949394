import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '..';
import { usePanels } from './Panels';
import { ConfigurationContextProvider } from './ConfigurationContext';

const { Panel } = Collapse;

export const Configurations = () => {
  const { t } = useTranslation();
  const panels = usePanels();

  return (
    <ConfigurationContextProvider>
      <PageHeaderCustom title={t('configurations.title')} />
      <ContentCustom>
        <Collapse>
          {panels.map((panel) => (
            <Panel key={panel.title} header={panel.title}>
              {panel.element}
            </Panel>
          ))}
        </Collapse>
      </ContentCustom>
    </ConfigurationContextProvider>
  );
};
