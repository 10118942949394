import { Switch, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useIconArray } from '../IconArray';

export const useColorDisplayAndIconsColumns = () => {
  const { t } = useTranslation();
  const icons = useIconArray();

  return [
    {
      title: t('configurations.columns.title'),
      dataIndex: 'title',
      editable: true,
      render: (title) => title,
      width: '25%'
    },
    {
      title: t('configurations.columns.color'),
      dataIndex: ['color'],
      editable: true,
      width: '10%',
      render: (color) => color && <Tag color={color}>{color}</Tag>
    },
    {
      title: t('configurations.columns.icon'),
      dataIndex: ['icon'],
      editable: true,
      width: '10%',
      render: (icon) => {
        const iconToRender = icons.find((iconItem) => icon === iconItem.label);
        if (iconToRender) return iconToRender.value;
        return null;
      }
    },
    {
      title: t('configurations.columns.display'),
      dataIndex: ['display'],
      editable: true,
      width: '10%',
      render: (display) => <Switch disabled checked={display} />
    }
  ];
};
