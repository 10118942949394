import { Form, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDraggerProps } from './utils/draggerProps/draggerProps';
import { useErrorMessage } from '../errorMessage';
import { inputDisplay } from './utils/inputDisplay';

/**
 * A custom React hook that generates a Form.Item for a form. This hook handles file inputs,
 * and sets up the necessary props for file drag-and-drop functionality. It's also responsible
 * for setting up the display of the Form.Item based on various options, including the type
 * of input and whether the Form.Item is hidden, disabled, etc.
 *
 * @function
 * @param {Array} fieldsFilesList - The list of files in the fields.
 * @param {function} setFieldsFileList - Function to set the fieldsFileList.
 * @param {Array} filesConfiguration - The configuration of the files.
 * @param {function} setFilesConfiguration - Function to set the files configuration.
 * @param {string} purpose - The purpose of the operation ('edit' or other values).
 * @param {function} deleteFile - Function to delete a file.
 * @param {function} setFilesToUpload - A function to set filesToUpload.
 * @param {function} downloadDocument - A function to download a document.
 * @param {function} viewDocument - A function to download a document.
 * @returns {function} A function that takes a resource and an options object, and returns a Form.Item.
 */
export const useGenerateFormItem = (
  fieldsFilesList,
  setFieldsFileList,
  filesConfiguration,
  setFilesConfiguration,
  purpose,
  deleteFile,
  setFilesToUpload,
  downloadDocument,
  viewDocument
) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();

  return (
    resource,
    {
      label,
      name,
      initialValue,
      dependencies,
      hasFeedback,
      validateStatus,
      rules,
      input,
      layout,
      noLabel,
      valuePropName,
      labelAlign,
      help,
      tooltip,
      translate = true,
      hidden = false,
      displayOnly = false,
      disabled,
      multipleFiles = true,
      maxFilesCount = 1,
      startWithDivider,
      endWithDivider
    }
  ) => {
    const isFileInput = input === 'File';
    const draggerProps =
      isFileInput &&
      useDraggerProps(
        fieldsFilesList,
        setFieldsFileList,
        purpose,
        filesConfiguration,
        deleteFile,
        downloadDocument,
        viewDocument,
        name,
        setFilesConfiguration,
        message,
        multipleFiles,
        maxFilesCount,
        t,
        setFilesToUpload
      );
    const formItemDisplay = inputDisplay(
      isFileInput,
      input,
      draggerProps,
      disabled,
      t
    );
    return (
      <div key={name}>
        {startWithDivider && (
          <Divider
            orientation={startWithDivider?.orientation}
            plain={startWithDivider?.plain}
            style={startWithDivider.style}
          >
            {startWithDivider?.title}
          </Divider>
        )}
        <Form.Item
          {...layout}
          labelAlign={labelAlign}
          colon={false}
          hidden={hidden}
          name={displayOnly ? undefined : name}
          initialValue={initialValue}
          dependencies={dependencies}
          label={
            !noLabel &&
            `${
              translate
                ? t(`${resource}.form.${label || name.join('.')}`)
                : `${label || name}`
            }`
          }
          hasFeedback={hasFeedback}
          rules={rules}
          valuePropName={valuePropName}
          validateStatus={validateStatus}
          help={help}
          tooltip={
            tooltip && {
              title: t(`${resource}.form.tooltip.${tooltip.name || name}`),
              overlayInnerStyle: { width: 500 }
            }
          }
        >
          {formItemDisplay}
        </Form.Item>
        {endWithDivider && (
          <Divider
            orientation={endWithDivider?.orientation}
            plain={endWithDivider?.plain}
            style={endWithDivider.style}
          >
            {endWithDivider?.title}
          </Divider>
        )}
      </div>
    );
  };
};
