import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const useFormatter = () => {
  const { t } = useTranslation();
  const formattedData = (data) => ({
    ...data,
    last_communication_date:
      data?.last_communication_date &&
      dayjs(data?.last_communication_date).format('DD/MM/YYYY'),
    prescription_date_1:
      data?.prescription_date_1 &&
      dayjs(data?.prescription_date_1).format('DD/MM/YYYY'),
    prescription_date_2:
      data?.prescription_date_1 &&
      dayjs(data?.prescription_date_1).format('DD/MM/YYYY'),
    registered_invoicing_date:
      data?.registered_invoicing_date &&
      dayjs(data?.registered_invoicing_date).format('DD/MM/YYYY'),
    created_at:
      data?.created_at && dayjs(data?.created_at).format('DD/MM/YYYY'),
    date_of_birth:
      data?.date_of_birth && dayjs(data?.date_of_birth).format('DD/MM/YYYY'),
    health_insurance:
      data?.health_insurance?.length > 0
        ? t('breadcrumbs.yes')
        : t('breadcrumbs.no'),
    initial_prescription:
      data?.initial_prescription?.length > 0
        ? t('breadcrumbs.yes')
        : t('breadcrumbs.no'),
    ald_prescription:
      data?.ald_prescription === true
        ? t('breadcrumbs.yes')
        : t('breadcrumbs.no'),
    ame: data?.ame ? t('breadcrumbs.yes') : t('breadcrumbs.no'),
    amc: data?.amc ? t('breadcrumbs.yes') : t('breadcrumbs.no'),
    medical_device_reference:
      data?.medical_device_reference === 'OTHER'
        ? t('breadcrumbs.other')
        : data?.medical_device_reference,
    type:
      data?.center?.type === 'PRIVATE'
        ? t('breadcrumbs.private')
        : t('breadcrumbs.public'),
    invoicing_start_date:
      data?.invoicing_start_date &&
      dayjs(data?.invoicing_start_date).format('DD/MM/YYYY'),
    invoicing_end_date:
      data?.invoicing_end_date &&
      dayjs(data?.invoicing_end_date).format('DD/MM/YYYY'),
    invoicing_forecast_date:
      data?.invoicing_forecast_date &&
      dayjs(data?.invoicing_forecast_date).format('DD/MM/YYYY'),
    risk:
      data?.risk === 'DISEASE' ? t('enums.disease') : t('enums.LOCAL REGIME'),
    exemption:
      data?.exemption === 'STANDARD'
        ? t('breadcrumbs.standard')
        : data?.exemption,
    switch_third_part_rac_date:
      data?.switch_third_part_rac_date &&
      dayjs(data?.switch_third_part_rac_date).format('DD/MM/YYYY'),
    quality:
      data?.quality === 'INSURED' ? t('enums.INSURED') : t('enums.RIGHT-HOLDER')
  });
  return { formattedData };
};
