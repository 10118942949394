/**
 * Prepares form data with files for submission.
 *
 * @function
 * @param {Array} files - Array of files from form fields.
 * @param {Array} draggerFilesList - Array of files from drag and drop interface.
 * @param {FormData} formData - FormData object that will be submitted.
 * @param {Array} filesConfiguration - Configuration array for files.
 * @param {string} purpose - Purpose of the createUpdateContainer.
 * @returns {void}
 */
export const handleFormDataWithFiles = (
  files,
  draggerFilesList,
  formData,
  filesConfiguration,
  purpose
) => {
  let filteredByConfiguration;
  if (purpose === 'edit') {
    const filteredDraggerFilesList = draggerFilesList.filter(
      (file) => !file.fileKey
    );
    filteredByConfiguration = filesConfiguration.filter(
      (fileConfig) =>
        filteredDraggerFilesList.some(
          (file) => fileConfig.name === file.name
        ) || fileConfig.source === 'field'
    );
  }

  if (draggerFilesList.length) {
    draggerFilesList.forEach((file) => {
      formData.append(`documents`, file);
    });
  }

  if (files.length) {
    files.forEach((file) => {
      formData.append(`documents`, file);
    });
  }

  if (draggerFilesList.length || files.length) {
    const configuration =
      purpose === 'create' ? filesConfiguration : filteredByConfiguration;
    formData.append('filesConfiguration', JSON.stringify(configuration));
  }
};
