import { useState } from 'react';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import { FolderStatusStats } from './FolderStatusStats';
import { useFormatter } from '../../utils/exportFormatter';

/**
 * Component for displaying a list of patients.
 * @returns {JSX.Element} - JSX for displaying a list of patients.
 */
export const ListPatients = () => {
  const columns = useColumns();
  const { formattedData } = useFormatter();
  const [totals, setTotals] = useState([]);

  return (
    <ListResource
      resourceName="patients"
      columns={columns}
      populate="center,prescriber,payingagency_amo,payingagency_amc"
      headers={headers}
      resourceModelName="Patient"
      setTotals={setTotals}
      formatter={formattedData}
      // eslint-disable-next-line react/no-children-prop
      children={<FolderStatusStats totals={totals} />}
    />
  );
};
