import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-components';
import { HomeOutlined } from '@ant-design/icons';
import { routes } from '../../utils/constants/adminRoutes';

/**
 * A custom page header component for application-wide use, featuring optional breadcrumbs and navigation.
 *
 * @component
 *
 * This component utilizes Ant Design's PageHeader and provides additional functionality, such as dynamic breadcrumbs
 * based on the current route and the option to include a back navigation button. It is designed to enhance navigation
 * within a React application by using React Router's `useLocation` and `useNavigate` hooks for route management. The
 * breadcrumbs are automatically generated from the current pathname, with options to exclude them or the back button
 * through component props. This component supports internationalization by translating breadcrumb names using the
 * `useTranslation` hook from `react-i18next`. It is flexible, allowing for additional elements (e.g., buttons) to be
 * passed as `extra` for rendering in the header's extra content area.
 *
 * @param {Object} props - Component properties.
 * @param {boolean|string} props.title - The title to display in the header, supports boolean for conditional rendering.
 * @param {React.Element} [props.extra=null] - Additional elements to be rendered in the header's extra content area.
 * @param {boolean} [props.withoutBreadCrums=false] - If `true`, breadcrumbs will not be displayed.
 * @param {boolean} [props.withoutNavigateBack=false] - If `true`, the back navigation button will not be displayed.
 * @returns {React.Component} A PageHeader component with custom functionality.
 */
export const PageHeaderCustom = ({
  title,
  extra,
  withoutBreadCrums,
  withoutNavigateBack
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitPathname = pathname.split('/').filter((p) => p !== '');
  let tmpPath;
  const breadCrumbs = splitPathname
    .slice(0, splitPathname.length - 1)
    .map((p) => {
      tmpPath = `/${p}`;
      return {
        path: tmpPath,
        breadcrumbName: t(`breadcrumbs.${p}`)
      };
    });
  const dynamicRoutes = [
    {
      path: routes.HOME,
      breadcrumbName: <HomeOutlined />
    },
    ...breadCrumbs
  ];

  const itemRender = (
    // eslint-disable-next-line react/prop-types
    { path, breadcrumbName, state, search, ...route },
    params,
    routesList
  ) => {
    const last = routesList.indexOf(route) === routesList.length - 1;
    return last ? (
      <span key={path}>{breadcrumbName}</span>
    ) : (
      <Link key={path} to={{ pathname: path, state, search }}>
        {breadcrumbName}
      </Link>
    );
  };

  return (
    <PageHeader
      breadcrumb={
        !withoutBreadCrums ? { items: dynamicRoutes, itemRender } : null
      }
      title={title}
      onBack={!withoutNavigateBack && (() => navigate(-1))}
      ghost={false}
      extra={extra}
    />
  );
};

PageHeaderCustom.propTypes = {
  title: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  extra: PropTypes.element,
  withoutBreadCrums: PropTypes.bool,
  withoutNavigateBack: PropTypes.bool
};

PageHeaderCustom.defaultProps = {
  extra: null,
  withoutBreadCrums: false,
  withoutNavigateBack: false
};
