/**
 * This function checks if every file in the filesList exists in the filesConfiguration.
 *
 * @function
 * @param {Array} filesList - Array of file objects.
 * @param {Array} filesConfiguration - The current configuration of the files.
 * @returns {boolean} Returns true if every file in the filesList exists in the filesConfiguration,
 * otherwise false. If filesConfiguration is empty, it also returns false.
 */
export const checkDraggerTypes = (filesList, filesConfiguration) => {
  if (filesConfiguration.length === 0) {
    return true;
  }
  const boolean = filesList.every((file) =>
    filesConfiguration.some((fileConfig) => file.name === fileConfig.name)
  );
  return boolean;
};
