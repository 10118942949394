import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin, Tabs } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/generateFormItem/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import FilesManager from './FilesManager/FilesManager';
import { handleFormDataWithFiles } from './utils/handleFormDataWithFiles';
import { handleFileActionsOnFetch } from './utils/handleFileActionsOnFetch';
import { checkDraggerTypes } from './utils/checkDraggerTypes';
import { useDownloadDocument } from '../../utils/downloadDoc';

/**
 * `CreateUpdateContainer` is a container for creating and updating resources, which includes form controls and various handlers for creating and updating resources.
 *
 * @component
 * @prop {string} purpose - Defines whether the purpose of the form is to 'edit' or 'create'.
 * @prop {Array} fields - An array of field objects for the form.
 * @prop {string} baseUrl - Base URL for the API endpoints.
 * @prop {string} resource - The name of the resource being created or edited.
 * @prop {boolean} [loadingFields=false] - A flag to determine whether the form fields are being loaded.
 * @prop {Object} [config={}] - A configuration object that provides handlers for getting, creating, and updating resources.
 * @prop {ReactElement} [formExtra=null] - Any extra form elements to be added.
 * @prop {string} [tradKey=null] - Key used for translation.
 * @prop {string} [submitLabel=null] - Label for the form submission button.
 * @prop {Function} [customSubmit=null] - A custom function to handle form submission.
 * @prop {boolean} [isParentLoading=false] - A flag to determine if the parent component is being loaded.
 * @prop {boolean} [withFilesManager=true] - A flag to determine whether to use a file manager in the form.
 * @param {Array<Object>} tabs - An array of tab configurations.
 * @param {string} activeKey - Active key for tabs.
 * @param {Function} setActiveKey - function to set the state activeKey.
 * @prop {boolean} [withFiles=false] - A flag to determine whether to use a file in the form.
 * @prop {boolean} [needFormValues=false] - A boolean to dtermine if we need formValues state.
 * @param {string} populate - Additional data to populate the form.
 * @param {Function} errorValidationAction - Custom error action.
 * @param {Function} setFormValues - Function to set form values externally.
 * @param {Function} refresh - Function to trigger form refresh.
 * @param {boolean} isOverlay - Indicates if the form is displayed as an overlay.
 * @param {Function} customNavigate - Custom navigation function.
 * @param {boolean} isFormData - A flag to determine if formData must be send.
 * @param {Object} customFormInstance - A flag to determine if a new form instance must be created.
 * @ref
 */
export const CreateUpdateContainer = forwardRef(
  (
    {
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading,
      withFilesManager,
      tabs,
      errorValidationAction,
      activeKey,
      setActiveKey,
      populate,
      withFiles,
      needFormValues,
      setFormValues,
      refresh,
      withHeader,
      isOverlay,
      customNavigate,
      fileList,
      fileInsurance,
      isFormData,
      customFormInstance
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [draggerFilesList, setDraggerFilesList] = useState([]);
    const [fieldsFilesList, setFieldsFileList] = useState([]);
    const [filesConfiguration, setFilesConfiguration] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [draggerFilesKeys, setDraggerFilesKeys] = useState([]);
    const [form] = Form.useForm();
    const { onGetResource, onCreateResource, onUpdateResource } = config;
    const { downloadDocument, viewDocument } = useDownloadDocument();

    const formInstance = customFormInstance || form;

    const getResourceFilesKeys = async () => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${baseUrl}/enums`
        });
        setDraggerFilesKeys(
          data.fileKeys
            .filter((enumItem) => enumItem.source === 'dragger')
            .map((enumItem) => enumItem.key)
        );
        const transformedObject = {};

        data.fileKeys.forEach((item) => {
          if (item.source === 'field') {
            transformedObject[item.key] = [];
          }
        });
        setFieldsFileList(transformedObject);
        return setIsLoading(false);
      } catch (e) {
        return message(e);
      }
    };

    const updateResource = async (body, files) => {
      setIsSubmitting(true);
      const formData = new FormData();

      handleFormDataWithFiles(
        files,
        draggerFilesList,
        formData,
        filesConfiguration,
        purpose
      );

      const values =
        onUpdateResource && onUpdateResource.setBody
          ? onUpdateResource.setBody(body)
          : body;

      formData.append(
        'values',
        JSON.stringify({
          ...values
        })
      );

      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${id}`,
          body: isFormData ? formData : values
        });
        if (isOverlay) {
          customNavigate();
        } else {
          navigate(-1);
        }
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const createResource = async (body, files) => {
      setIsSubmitting(true);
      const formData = new FormData();

      handleFormDataWithFiles(
        files,
        draggerFilesList,
        formData,
        filesConfiguration,
        purpose
      );

      const values =
        onCreateResource && onCreateResource.setBody
          ? onCreateResource.setBody(body, formData)
          : body;

      if (fileList.length > 0) {
        fileList.forEach((file) => {
          formData.append('documents', file);
        });
      }

      if (fileInsurance.length > 0) {
        fileInsurance.forEach((file) => {
          formData.append('health_insurance', file);
        });
      }

      formData.append(
        'values',
        JSON.stringify({
          ...values
        })
      );

      try {
        await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body: isFormData ? formData : body
        });

        if (isOverlay) {
          customNavigate();
          setIsSubmitting(false);
        } else {
          navigate(-1);
        }
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const deleteFile = async (fileID) => {
      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${id}/${fileID}`
        });
      } catch (e) {
        message(e);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const pop =
          populate || withFiles
            ? `?populate=${populate || ''}${withFiles ? 'documents.file' : ''}`
            : '';
        const { data } = await dispatchAPI('GET', {
          url: `${baseUrl}/${id}${pop}`
        });

        if (withFiles) {
          handleFileActionsOnFetch(
            data,
            setFieldsFileList,
            setDraggerFilesList,
            setFilesConfiguration,
            dispatchAPI,
            message
          );
        }

        formInstance.setFieldsValue(
          onGetResource && onGetResource.setFields
            ? onGetResource.setFields(data)
            : data
        );

        if (needFormValues)
          setFormValues({
            ...(onGetResource && onGetResource.setFields
              ? onGetResource.setFields(data)
              : data)
          });
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl]);

    useEffect(() => {
      (async () => {
        if (purpose === 'edit' && id) {
          await getResource();
        }
        if (withFiles) await getResourceFilesKeys();
      })();
    }, [getResource, refresh]);

    const handleSubmit = async (values) => {
      const extractedFileKeys = filesToUpload.map(
        (fileObject) => fileObject.file
      );
      const boolean = checkDraggerTypes(draggerFilesList, filesConfiguration);

      switch (true) {
        case !boolean:
          return message(t('missing_types'));
        case customSubmit:
          return customSubmit(values, extractedFileKeys);
        case purpose === 'edit':
          await updateResource(values, extractedFileKeys);
          return true;
        default:
          await createResource(values, extractedFileKeys);
      }

      return true;
    };

    const handleFinishFailed = (errorInfo) => {
      if (errorValidationAction) {
        errorValidationAction(errorInfo);
      }
    };

    const onChangeActiveTab = (key) => {
      setActiveKey(key);
    };

    const generateFieldsMemoized = useCallback(
      useGenerateFormItem(
        fieldsFilesList,
        setFieldsFileList,
        filesConfiguration,
        setFilesConfiguration,
        purpose,
        deleteFile,
        setFilesToUpload,
        downloadDocument,
        viewDocument
      ),
      [fieldsFilesList, filesConfiguration]
    );

    const handleChange = () => {
      if (setFormValues) {
        const formData = formInstance.getFieldsValue();
        setFormValues({ ...formData });
      }
    };

    return (
      <ContentCustom>
        {withHeader && (
          <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
        )}
        <Spin spinning={isLoading || isParentLoading}>
          <Form
            ref={ref}
            {...formItemLayout}
            onValuesChange={handleChange}
            onFinishFailed={handleFinishFailed}
            onFinish={handleSubmit}
            form={formInstance}
          >
            {tabs?.length ? (
              <Tabs
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                items={tabs}
                onChange={onChangeActiveTab}
                className="createupdate-tabs"
              />
            ) : (
              <>
                {fields.map((field) =>
                  generateFieldsMemoized(tradKey || resource, field)
                )}
              </>
            )}
            {formExtra}
            {withFilesManager && (
              <FilesManager
                filesList={draggerFilesList}
                setFilesList={setDraggerFilesList}
                filesKeys={draggerFilesKeys}
                filesConfiguration={filesConfiguration}
                setFilesConfiguration={setFilesConfiguration}
                purpose={purpose}
                deleteFile={deleteFile}
              />
            )}

            <Form.Item {...tailFormItemLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 8px' }}
                  type="link"
                  danger
                  onClick={() => (isOverlay ? customNavigate() : navigate(-1))}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="primary" htmlType="submit" loading={isSubmitting}>
                  {`${t(submitLabel || 'buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </ContentCustom>
    );
  }
);

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  isFormData: PropTypes.bool,
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  withFilesManager: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
  activeKey: PropTypes.string,
  setActiveKey: PropTypes.func,
  withFiles: PropTypes.bool,
  errorValidationAction: PropTypes.func,
  populate: PropTypes.string,
  needFormValues: PropTypes.bool,
  setFormValues: PropTypes.func,
  refresh: PropTypes.bool,
  isOverlay: PropTypes.bool,
  customNavigate: PropTypes.func,
  withHeader: PropTypes.bool,
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  fileInsurance: PropTypes.arrayOf(PropTypes.shape({})),
  customFormInstance: PropTypes.shape({ setFieldsValue: PropTypes.func })
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  withFilesManager: true,
  tabs: [],
  activeKey: '1',
  setActiveKey: null,
  withFiles: false,
  errorValidationAction: null,
  populate: null,
  needFormValues: false,
  setFormValues: null,
  refresh: false,
  isOverlay: false,
  customNavigate: null,
  withHeader: true,
  fileList: [],
  fileInsurance: [],
  isFormData: false,
  customFormInstance: null
};
