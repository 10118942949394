import { useTranslation } from 'react-i18next';
import { Configuration } from './Configuration';
import { useColorDisplayAndIconsColumns } from './Columns/ColorDisplayAndIconsColumns';

export const usePanels = () => {
  const { t } = useTranslation();
  const colorDisplayAndIconsColumns = useColorDisplayAndIconsColumns();

  return [
    {
      element: (
        <Configuration
          resourceName="test-categories"
          resourceColumns={colorDisplayAndIconsColumns}
        />
      ),
      title: t('configurations.titles.test-categories')
    }
  ];
};
