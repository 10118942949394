export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 10 },
    lg: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 10 },
    lg: { span: 8 }
  }
};

export const noLabelLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { offset: 12, span: 10 },
    md: { offset: 10, span: 10 },
    lg: { offset: 8, span: 8 }
  }
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { offset: 12, span: 10 },
    md: { offset: 10, span: 10 },
    lg: { offset: 8, span: 8 }
  }
};

export const drawerFormLayout = {
  labelCol: {
    xs: { span: 24 },
    lg: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 14 }
  }
};

export const drawerTailFormLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    lg: {
      span: 14,
      offset: 10
    }
  }
};
