import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Configurations } from '../components/Configurations/Configurations';

const ConfigurationRouter = () => (
  <Routes>
    <Route index element={<Configurations />} />
  </Routes>
);

export default ConfigurationRouter;
